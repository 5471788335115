'use client';

import { Button, Card, Header } from '@/components';
import Config from '@/config';
import { formatCurrency, formatDate } from '@/helpers';
import { JobOrder } from '@/models';
import {
  ArrowLeftIcon,
  BanknotesIcon,
  BriefcaseIcon,
  BuildingOfficeIcon,
  CalendarIcon,
  IdentificationIcon,
  MapIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { useCallback, useState } from 'react';

import JobDetailOrderListItem from './jobDetailOrderListItem';

type JobDetailCardProps = {
  job: JobOrder;
  onBack: () => void;
};

export default function JobDetailCard(props: JobDetailCardProps) {
  const { job, onBack } = props;

  const [hovered, setIsHovered] = useState(false);

  const onToggle = useCallback(() => {
    setIsHovered(value => !value);
  }, []);

  return (
    <Card>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-4">
            <div className="flex self-center">
              <BriefcaseIcon
                className="text-lightgreen"
                height={42}
                width={42}
              />
            </div>
            <Header as="h5" override="h4" variant="secondary">
              {job.title.replace("VMS", "").trim()}
            </Header>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <Header as="h6" override="h4" variant="primary">
            Job Details
          </Header>
          <div className="flex flex-col gap-1">
            {!!job.payRate && (
              <div className="flex items-center gap-3">
                <BanknotesIcon className="text-gray" width={24} height={24} />
                <p className="text-sm text-darkblue">
                  Pay:{' '}
                  <span className="lowercase">
                    {formatCurrency(job.payRate)} {job.salaryUnit || ''}
                  </span>
                </p>
              </div>
            )}
            <div className="flex items-center gap-3">
              <UsersIcon className="text-gray" width={24} height={24} />
              <p className="text-sm text-darkblue">
                Openings: {(job.numOpenings || 0).toLocaleString()}
              </p>
            </div>
            {job.address?.state && (
              <div className="flex items-center gap-3">
                <MapIcon className="text-gray" width={24} height={24} />
                <p className="text-sm text-darkblue">
                  State: {job.address.state}
                </p>
              </div>
            )}
            {!!job.startDate && (
              <div className="flex items-center gap-3">
                <CalendarIcon className="text-gray" width={24} height={24} />
                <p className="text-sm text-darkblue">
                  Start Date: {formatDate(job.startDate)}
                </p>
              </div>
            )}
            {!!job.dateEnd && (
              <div className="flex items-center gap-3">
                <CalendarIcon className="text-gray" width={24} height={24} />
                <p className="text-sm text-darkblue">
                  End Date: {formatDate(job.dateEnd)}
                </p>
              </div>
            )}
            {!!job.onSite && (
              <div className="flex items-center gap-3">
                <BuildingOfficeIcon
                  className="text-gray"
                  width={24}
                  height={24}
                />
                <p className="text-sm text-darkblue">Setting: {job.onSite}</p>
              </div>
            )}
            <div className="flex items-center gap-3">
              <IdentificationIcon
                className="text-gray"
                width={24}
                height={24}
              />
              <p className="text-sm text-gray">ID: {job.id}</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <Header as="h6" override="h4" variant="primary">
            {Config.COMPANY_NAME} offers you
          </Header>
          <ul role="list" className="flex flex-col gap-3 text-sm">
            <JobDetailOrderListItem
              href="/physicians-and-apps/pay-and-benefits/"
              label="Superior malpractice coverage"
            />
            <JobDetailOrderListItem
              href="/physicians-and-apps/travel-and-accommodations/"
              label="Travel and lodging coordination, including assisting family
                members and travel preferences"
            />
            <JobDetailOrderListItem
              href="/physicians-and-apps/what-is-the-concierge-approach/"
              label="A Concierge Team to assist with dinner plans, pet care,
                gifts, gyms and to make you feel more at home in your new
                destination"
            />
            <JobDetailOrderListItem
              href="/physicians-and-apps/what-is-the-concierge-approach/"
              label="Senior healthcare consultants, with extensive locums
                background available 24/7"
            />
            <JobDetailOrderListItem
              href="/physicians-and-apps/locums-lifestyle/"
              label="A panel of locums work style experts at your disposal in
                various disciplines, including legal support, benefits
                administration, financial planning, tax planning, and many
                others"
            />
          </ul>
        </div>
        <div className="flex flex-col gap-2">
          <Header as="h6" override="h4" variant="primary">
            About {Config.COMPANY_NAME}
          </Header>
          <p className="text-sm text-darkblue font-light">
            We endeavor to be your partner, creating personalized career
            solutions that are mindful of your goals and positively impact
            patient outcomes. Let {Config.COMPANY_NAME} be your advocate and
            your career concierge because you deserve an expert.
          </p>
        </div>
        <div>
          <Button
            onClick={onBack}
            onMouseEnter={onToggle}
            onMouseLeave={onToggle}
          >
            <ArrowLeftIcon
              className={`me-2 ease-in-out duration-100 ${
                hovered ? '-translate-x-1' : ''
              }`}
              width={12}
              height={12}
            />
            Go back
          </Button>
        </div>
      </div>
    </Card>
  );
}
