import { JobOrder } from '@/models';

import { formatCurrency } from './numberFormat';
import states from '@/data/states';

const SALARY_UNITS: { [key: string]: string } = {
  default: '',
  hour: 'hr',
  month: 'mo',
  week: 'wk',
};

export function formatJob(job: JobOrder) {
  const categories = job.categories?.data || [];
  const categoriesOfEmployment = job.customText11 || [];
  const categoryNames = categories.map(({ name }) => name.trim());
  const output = [...categoryNames, ...categoriesOfEmployment];

  const dedupped = output.reduce((working, next) => {
    const trimmed = next.trim();
    const isPhysician = trimmed === 'DO' || trimmed === 'MD';
    const value = isPhysician ? 'Physician' : next;

    return working.includes(value) ? working : [...working, value];
  }, [] as string[]);

  const addressState = job.address?.state || '';
  const state = states[addressState] || addressState;
  const stateFormatted = state ? ` in ${state}` : '';

  const employmentType = job.employmentType === "VMS" ? "" : job.employmentType;
  return `${employmentType} ${dedupped.join(', ')} Job${stateFormatted}`.trim();

}

export function formatJobMetadata(job: JobOrder, shouldPublishPay: boolean) {
  const jobTitle = formatJob(job);
  const payRate = shouldPublishPay ? job.payRate : null;
  const salaryUnitPart =
    job.salaryUnit?.toLowerCase().replace('per', '').trim() || '';
  const salaryUnit = SALARY_UNITS[salaryUnitPart] || '';
  const pay =
    payRate && salaryUnit ? `${formatCurrency(payRate)}/${salaryUnit} for` : '';

  return `${pay} ${jobTitle}`.trim();
}
