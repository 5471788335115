'use client';

import { Card, Header } from '@/components';
import Config from '@/config';
import { Candidate, JobOrder, LabelValueResult } from '@/models';

import CandidateForm from './candidateForm';
import { useEffect, useState } from 'react';

type CandidateFormCardProps = {
  initialEmail: string;
  job: JobOrder;
  onSubmit: (values: Candidate) => Promise<void>;
};

type CandidateFormOptions = {
  categories: LabelValueResult<number> | null;
  locations: LabelValueResult<string> | null;
  titles: LabelValueResult<string> | null;
};

export default function CandidateFormCard(props: CandidateFormCardProps) {
  const { initialEmail, job, onSubmit } = props;

  const [{ categories, locations, titles }, setOptions] =
    useState<CandidateFormOptions>({
      categories: null,
      locations: null,
      titles: null,
    });

  useEffect(() => {
    const getOptions = async () => {
      const requests = [
        fetch('/api/categories'),
        fetch('/api/locations'),
        fetch('/api/titles'),
      ];
      const responses = await Promise.all(requests);
      const values = await Promise.all(
        responses.map(response => response.json()),
      );

      setOptions({
        categories: values[0],
        locations: values[1],
        titles: values[2],
      });
    };

    getOptions();
  }, []);

  return (
    <Card>
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <Header as="h6" override="h4" variant="primary">
            Register with {Config.COMPANY_NAME}
          </Header>
          {/* <p className="text-sm text-darkblue font-light">
            Register to access facility information, save your searches and get
            job alerts tailored to you.
          </p> */}
        </div>
        <CandidateForm
          categories={categories}
          initialEmail={initialEmail}
          locations={locations}
          job={job}
          titles={titles}
          onSubmit={onSubmit}
        />
      </div>
    </Card>
  );
}
