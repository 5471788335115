const Config = {
  BASE_METADATA: process.env.BASE_METADATA || '',
  BASE_URL: process.env.URL || process.env.BASE_URL || '',
  BULLHORN_ACTION: process.env.BULLHORN_ACTION!,
  BULLHORN_BASE_REST_URL: process.env.BULLHORN_BASE_REST_URL!,
  BULLHORN_CLIENT_ID: process.env.BULLHORN_CLIENT_ID!,
  BULLHORN_CLIENT_SECRET: process.env.BULLHORN_CLIENT_SECRET!,
  BULLHORN_API_PASSWORD: process.env.BULLHORN_API_PASSWORD!,
  BULLHORN_API_USERNAME: process.env.BULLHORN_API_USERNAME!,
  BULLHORN_HIDE_CATEGORY_IDS:
    process.env.BULLHORN_HIDE_CATEGORY_IDS?.split(',').map(Number) || [],
  BULLHORN_PAGE_SIZE: Number(process.env.NEXT_PUBLIC_BULLHORN_PAGE_SIZE!),
  BULLHORN_WEB_FORM_SOURCE: process.env.BULLHORN_WEB_FORM_SOURCE || '',
  BULLHORN_JOB_ORDER_FIELDS: process.env.BULLHORN_JOB_ORDER_FIELDS!,
  BULLHORN_JOB_ORDER_SORT: process.env.BULLHORN_JOB_ORDER_SORT!,
  BULLHORN_JOB_ORDER_QUERY: process.env.BULLHORN_JOB_ORDER_QUERY!,
  BULLHORN_JOB_TITLES: process.env.BULLHORN_JOB_TITLES?.split(',') || [],
  BULLHORN_JOB_ORDER_VALID_EMPLOYMENT_TYPES:
    process.env.BULLHORN_JOB_ORDER_VALID_EMPLOYMENT_TYPES?.split(',') || [],
  BULLHORN_JOB_ORDER_VALID_JOB_STATUSES:
    process.env.BULLHORN_JOB_ORDER_VALID_JOB_STATUSES?.split(',') || [],
  COMPANY_DESCRIPTION: process.env.NEXT_PUBLIC_COMPANY_DESCRIPTION!,
  COMPANY_NAME: process.env.NEXT_PUBLIC_COMPANY_NAME!,
  COMPANY_WEBSITE: process.env.NEXT_PUBLIC_COMPANY_WEBSITE!,
  GA_ID: process.env.NEXT_PUBLIC_GA_ID!,
  GRAPHQL_URL: process.env.NEXT_PUBLIC_GRAPHQL_URL!,
};

export default Config;
