'use client';

import { Card, Header } from '@/components';
import { formatCurrency, formatDate, pluralize } from '@/helpers';
import { JobOrder } from '@/models';
import {
  BanknotesIcon,
  BuildingOfficeIcon,
  CalendarIcon,
  ChevronRightIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { useEffect } from 'react';
import ImageWithFallback from '../image/imageWithFallback';

type JobCardProps = {
  job: JobOrder;
  selected?: boolean;
};

export default function JobCard(props: JobCardProps) {
  const { job, selected } = props;

  const jobOpenings = job.numOpenings || 0; // Use numOpenings or default to 0

  // Log job data in browser console
  useEffect(() => {
    console.log('JobCard Props:', {
      job: { id: job.id, title: job.title, numOpenings: job.numOpenings },
      selected,
    });
  }, [job, selected]);

  const jobPosting = {
    "@context": "https://schema.org",
    "@type": "JobPosting",
    "title": job.title,
    "description": job.publicDescription || "No description provided",
    "datePosted": job.dateAdded,
    "employmentType": job.employmentType === "VMS" ? "" : job.employmentType,
    "hiringOrganization": {
      "@type": "Organization",
      "name": job.publishedCategory?.name || "Unknown Organization",
    },
    "jobLocation": {
      "@type": "Place",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": job.address?.city || "Unknown",
        "addressLocality": job.address?.city || "Unknown",
        "addressRegion": job.address?.state || "Unknown",
        "addressCountry": job.address?.countryName || "Unknown",
      },
    },
    "salaryCurrency": job.salaryUnit || "USD",
    "baseSalary": {
      "@type": "MonetaryAmount",
      "value": {
        "@type": "QuantitativeValue",
        "value": job.payRate || 0,
        "unitText": job.salaryUnit || "Hourly",
      },
    },
  };

  return (
    <div className="group cursor-pointer">
      <Card animate padding={false} selected={selected}>
        <script type="application/ld+json">
          {JSON.stringify(jobPosting)}
        </script>
        <div className="flex flex-col lg:flex-row lg:items-center gap-2">
          <div className="w-full h-32 lg:w-1/4 lg:h-full rounded-t-xl lg:rounded-tr-none lg:rounded-s-xl overflow-hidden relative flex-none">
            <ImageWithFallback
              alt={job.title}
              className="group-hover:scale-[1.1] duration-500 transition-all ease-in-out block inset-0 z-10 w-full h-full object-cover lg:rounded-tr-none lg:rounded-s-xl"
              src={job.thumbnailImageUrl}
              width={200}
              height={230}
            />
            <div className="bg-lightgreen/60 absolute top-0 left-0 right-0 bottom-0 z-10 lg:rounded-tr-none lg:rounded-s-xl"></div>
          </div>
          <div className="flex justify-between items-center gap-4 grow p-5 sm:px-9 sm:py-10">
            <div className="flex flex-col gap-4">
              <Header as="h6" override="h4" variant="primary">
                {job.title}
              </Header>
              <div className="flex flex-col space-y-1">
                {!!job.payRate && (
                  <div className="flex items-center gap-3">
                    <BanknotesIcon
                      className="text-gray"
                      width={24}
                      height={24}
                    />
                    <p className="text-sm text-darkblue lowercase">
                      {formatCurrency(job.payRate)} {job.salaryUnit || ''}
                    </p>
                  </div>
                )}
                <div className="flex items-center gap-3">
                  <UsersIcon className="text-gray" width={24} height={24} />
                  <p className="text-sm text-darkblue">
                    {jobOpenings.toLocaleString()} Opening
                    {pluralize(jobOpenings)}
                  </p>
                </div>
                {!!job.onSite && (
                  <div className="flex items-center gap-3">
                    <BanknotesIcon
                      className="text-gray"
                      width={24}
                      height={24}
                    />
                    <p className="text-sm text-darkblue">{job.onSite}</p>
                  </div>
                )}
                {!!job.startDate && (
                  <div className="flex items-center gap-3">
                    <CalendarIcon
                      className="text-gray"
                      width={24}
                      height={24}
                    />
                    <p className="text-sm text-darkblue">
                      Start on {formatDate(job.startDate)}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="hidden lg:block">
              <ChevronRightIcon
                className="group-hover:scale-125 group-hover:translate-x-1 duration-500 transition-all ease-in-out text-lightgreen"
                width={32}
                height={32}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}