'use client';

import {
  Concierge,
  JobOrder,
  LabelValueItem,
  LabelValueResult,
  PageResult,
} from '@/models';
import { useCallback, useState, useEffect } from 'react';
import Pager from '../pager/pager';
import JobCard from './jobCard';
import JobCardLoading from './jobCardLoading';
import JobFilter from './jobFilter';
import ConciergeFormCard from '../concierge/conciergeFormCard';
import ConciergeThankYouCard from '../concierge/conciergeThankYouCard';
import Link from 'next/link';

enum ConciergeApplicationStates {
  Apply,
  ApplyError,
  ApplyThankYou,
}

type JobContainerProps = {
  categories?: LabelValueResult<number> | null;
  isLoading: boolean;
  jobs?: PageResult<JobOrder>;
  locations?: LabelValueResult<string> | null;
  titles?: LabelValueResult<string> | null;
  onCategoryFilterChange: (items: LabelValueItem<number>[]) => void;
  onIMLCFilterChange: (items: LabelValueItem<string>[]) => void;
  onLocationFilterChange: (items: LabelValueItem<string>[]) => void;
  onPage: (page: number) => void;
  onResetFilter: () => void;
  onTitleFilterChange: (items: LabelValueItem<string>[]) => void;
  scrollToTop: () => void;
};

export default function JobContainer(props: JobContainerProps) {
  const {
    categories,
    isLoading,
    locations,
    jobs,
    titles,
    onCategoryFilterChange,
    onIMLCFilterChange,
    onLocationFilterChange,
    onPage,
    onResetFilter,
    onTitleFilterChange,
    scrollToTop,
  } = props;

  const [conciergeApplicationState, setConciergeApplicationState] = useState(
    ConciergeApplicationStates.Apply,
  );

  // Detailed logging in browser console
  useEffect(() => {
    console.log('JobContainer Props:', {
      isLoading,
      jobs: jobs ? {
        count: jobs.count,
        start: jobs.start,
        total: jobs.total,
        data: jobs.data?.map(job => ({ id: job.id, title: job.title, numOpenings: job.numOpenings })),
      } : null,
      hasJobs: jobs?.data && jobs.data.length > 0,
      categories: categories ? categories.data?.map(c => c.label) : null,
      locations: locations ? locations.data?.map(l => l.label) : null,
      titles: titles ? titles.data?.map(t => t.label) : null,
    });
  }, [isLoading, jobs, categories, locations, titles]);

  const onSelectPage = useCallback(
    (page: number) => {
      console.log('Paging to:', page);
      onPage(page);
      scrollToTop();
    },
    [onPage, scrollToTop],
  );

  const onSubmitConcierge = useCallback(async (values: Concierge) => {
    let applicationState = ConciergeApplicationStates.Apply;

    try {
      const body = new FormData();
      const anyValues = values as { [key: string]: any };
      const objectKeys = ['state', 'profession', 'specialty', 'licensedStates'];

      Object.keys(anyValues).forEach(key =>
        body.append(
          key,
          objectKeys.includes(key)
            ? JSON.stringify(anyValues[key])
            : anyValues[key],
        ),
      );

      body.append('formName', 'Jobs Portal - Request a Custom Search');

      const response = await fetch('/api/candidates', {
        body,
        method: 'POST',
      });

      applicationState = response.ok
        ? ConciergeApplicationStates.ApplyThankYou
        : ConciergeApplicationStates.ApplyError;
    } catch (error: any) {
      applicationState = ConciergeApplicationStates.ApplyError;
      console.log('Concierge Submission Error:', error);
    }

    setConciergeApplicationState(applicationState);
  }, []);

  const onReset = useCallback(() => {
    setConciergeApplicationState(ConciergeApplicationStates.Apply);
    onResetFilter();
  }, [onResetFilter]);

  const hasJobs = jobs?.data && jobs.data.length > 0;

  return (
    <div className="relative -top-20 lg:-top-24">
      <div className="pt-12 mb-12 gap-5">
        <div className="flex flex-col lg:flex-row gap-8">
          <div className="lg:w-2/6">
            <JobFilter
              categories={categories}
              locations={locations}
              titles={titles}
              onResetFilter={onResetFilter}
              onSelectCategory={onCategoryFilterChange}
              onSelectIMLC={onIMLCFilterChange}
              onSelectLocation={onLocationFilterChange}
              onSelectTitle={onTitleFilterChange}
            />
          </div>
          {isLoading && (
            <div className="flex flex-col flex-1 gap-5">
              <JobCardLoading key="job-card-loading-0" />
              <JobCardLoading key="job-card-loading-1" />
              <JobCardLoading key="job-card-loading-2" />
              <JobCardLoading key="job-card-loading-3" />
              <JobCardLoading key="job-card-loading-4" />
            </div>
          )}
          {!isLoading && (
            <div className="flex flex-col flex-1 gap-5">
              {hasJobs ? (
                jobs.data.map(job => (
                  <Link key={`job-${job.id}`} href={`${job.id}`}>
                    <JobCard job={job} />
                  </Link>
                ))
              ) : (
                <div>No jobs found</div> // Explicit message if no jobs
              )}
              <Pager
                count={jobs?.count || 0}
                start={jobs?.start || 0}
                total={jobs?.total || 0}
                onPage={onSelectPage}
              />
              {!hasJobs &&
                (conciergeApplicationState === ConciergeApplicationStates.Apply ||
                  conciergeApplicationState === ConciergeApplicationStates.ApplyError) && (
                  <ConciergeFormCard
                    categories={categories}
                    locations={locations}
                    onSubmitConcierge={onSubmitConcierge}
                  />
                )}
              {!hasJobs &&
                conciergeApplicationState === ConciergeApplicationStates.ApplyThankYou && (
                  <ConciergeThankYouCard onResetFilter={onReset} />
                )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}