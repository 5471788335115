'use client';

import { Candidate, JobOrder, JobSubmission } from '@/models';
import { useRouter } from 'next/navigation';
import { useCallback, useState, useEffect } from 'react';
import Head from 'next/head';
import StructuredData from '../StructuredData';
import CandidateFormCard from '../candidate/candidateFormCard';
import CandidateThankYouCard from '../candidate/candidateThankYouCard';
import JobSubmissionFormCard from '../jobSubmission/jobSubmissionFormCard';
import JobSubmissionThankYouCard from '../jobSubmission/jobSubmissionThankYouCard';
import JobDetailCard from './jobDetailCard';
import InnerContent from '../layout/innerContent';
import Hero from '../layout/hero';
import { ScrollToTop } from '../layout/scrollToTop';

enum JobApplicationStates {
  Apply,
  ApplyError,
  ApplyThankYou,
  Register,
  RegisterError,
  RegisterThankYou,
}

type JobDetailProps = { 
  job: JobOrder | null;
  jobPostingStructuredData: string | null;
  debugInfo?: any[]; // Add debugInfo as an optional prop
};

export default function JobDetail(props: JobDetailProps) {
  const { job, jobPostingStructuredData, debugInfo } = props;
  const router = useRouter();

  const [jobApplicationState, setJobApplicationState] = useState(JobApplicationStates.Apply);
  const [initialEmail, setInitialEmail] = useState('');

  // Log debugInfo and props in browser console
  useEffect(() => {
    if (debugInfo) {
      console.log('JobDetail Debug Info from Server:', debugInfo);
    }
    console.log('JobDetail Props:', {
      job: job ? { id: job.id, title: job.title, numOpenings: job.numOpenings } : null,
      jobPostingStructuredData,
    });
  }, [job, jobPostingStructuredData, debugInfo]);

  const formattedDate = job?.dateAdded && typeof job.dateAdded === 'string' && !isNaN(Date.parse(job.dateAdded)) 
    ? new Date(job.dateAdded).toISOString() 
    : new Date().toISOString();

  const isoDate = formattedDate;

  const sendJobSubmission = useCallback(async (values: JobSubmission) => {
    let applicationState = JobApplicationStates.Apply;

    try {
      const response = await fetch('/api/jobSubmissions', {
        body: JSON.stringify(values),
        method: 'POST',
      });

      applicationState = response.ok
        ? JobApplicationStates.ApplyThankYou
        : JobApplicationStates.Register;
    } catch (error: any) {
      applicationState = JobApplicationStates.ApplyError;
      console.log('Job Submission Error:', error);
    }

    return applicationState;
  }, []);

  const scrollToTop = useCallback(() => {
    const yOffset = -180;
    const element = document.querySelector('main');
    const top = element
      ? element.getBoundingClientRect().top + window.scrollY + yOffset
      : 0;

    window.scrollTo({ top, behavior: 'smooth' });
  }, []);

  const onSubmitJob = useCallback(
    async (values: JobSubmission) => {
      const { email } = values;

      const applicationState = await sendJobSubmission(values);

      setInitialEmail(email);
      setJobApplicationState(applicationState);
      scrollToTop();
    },
    [scrollToTop, sendJobSubmission],
  );

  const onSubmitCandidate = useCallback(
    async (values: Candidate) => {
      let applicationState = JobApplicationStates.Register;

      try {
        const body = new FormData();
        const anyValues = values as { [key: string]: any };
        const objectKeys = ['state', 'profession', 'specialty', 'licensedStates'];

        Object.keys(anyValues).forEach(key =>
          body.append(
            key,
            objectKeys.includes(key)
              ? JSON.stringify(anyValues[key])
              : anyValues[key],
          ),
        );

        body.append('formName', 'Jobs Portal - Apply Now');

        const response = await fetch('/api/candidates', {
          body,
          method: 'POST',
        });

        applicationState = response.ok
          ? JobApplicationStates.RegisterThankYou
          : JobApplicationStates.RegisterError;
      } catch (error: any) {
        applicationState = JobApplicationStates.RegisterError;
        console.log('Candidate Submission Error:', error);
      }

      if (applicationState !== JobApplicationStates.RegisterError && job?.id) {
        applicationState = await sendJobSubmission({
          accept: values.accept,
          email: values.email,
          jobOrderId: job.id,
        });
      }

      applicationState =
        applicationState === JobApplicationStates.ApplyError
          ? JobApplicationStates.RegisterError
          : applicationState;

      setJobApplicationState(applicationState);
      scrollToTop();
    },
    [job?.id, scrollToTop, sendJobSubmission],
  );

  const structuredData = job
    ? {
        "@context": "https://schema.org",
        "@type": "JobPosting",
        "title": job.title.replace("VMS", ""),
        "description": job.metadata || "Details available upon request.",
        "datePosted": isoDate,
        "employmentType": job.employmentType === "VMS" ? "" : job.employmentType,
        "identifier": { "@type": "PropertyValue", "name": "Job ID", "value": job.id },
        "hiringOrganization": {
          "@type": "Organization",
          "name": "Floyd Lee Locums",
          "sameAs": "https://floydleelocums.com/"
        },
        "jobLocation": {
          "@type": "Place",
          "address": {
            "@type": "PostalAddress",
            "addressLocality": job.address?.city || "Unknown",
            "addressRegion": job.address?.state || "Unknown",
            "addressCountry": "US"
          }
        },
        "baseSalary": {
          "@type": "MonetaryAmount",
          "currency": "USD",
          "value": { "@type": "QuantitativeValue", "unitText": "HOUR", "value": job.payRate || "Negotiable" }
        }
      }
    : null;

  useEffect(() => {
    if (structuredData) {
      const script = document.createElement("script");
      script.type = "application/ld+json";
      script.text = JSON.stringify(structuredData);
      document.body.appendChild(script);
    }
  }, [structuredData]);

  return (
    <>
      <Head>
        <StructuredData data={structuredData} />
      </Head>
      <ScrollToTop />
      {job?.imageUrl && (
        <Hero
          collapsed
          heroAlt="Search jobs nationwide"
          heroSrc={job?.imageUrl}
        >
          <InnerContent>
            <div className="space-y-5"></div>
          </InnerContent>
        </Hero>
      )}
      <main>
        <InnerContent>
          <div className="relative -top-32">
            <div className="flex flex-col lg:flex-row gap-5">
              {job && (
                <div className="w-full lg:w-1/2">
                  <JobDetailCard job={job} onBack={router.back} />
                </div>
              )}
              <div className="w-full lg:w-1/2">
                {job &&
                  (jobApplicationState === JobApplicationStates.Apply ||
                    jobApplicationState === JobApplicationStates.ApplyError) && (
                    <JobSubmissionFormCard
                      employmentType={job.employmentType === "VMS" ? "" : job.employmentType}
                      jobOrderId={job.id}
                      onSubmit={onSubmitJob}
                    />
                  )}
                {job &&
                  jobApplicationState === JobApplicationStates.ApplyThankYou && (
                    <JobSubmissionThankYouCard
                      employmentType={job.employmentType === "VMS" ? "" : job.employmentType}
                    />
                  )}
                {job &&
                  (jobApplicationState === JobApplicationStates.Register ||
                    jobApplicationState === JobApplicationStates.RegisterError) && (
                    <CandidateFormCard
                      job={job}
                      initialEmail={initialEmail}
                      onSubmit={onSubmitCandidate}
                    />
                  )}
                {jobApplicationState === JobApplicationStates.RegisterThankYou && (
                  <CandidateThankYouCard />
                )}
              </div>
            </div>
          </div>
        </InnerContent>
      </main>
    </>
  );
}