'use client';

import { JobContainer } from '@/components';
import InnerContent from '@/components/layout/innerContent';
import { ScrollToTop } from '@/components/layout/scrollToTop';
import Config from '@/config';
import FilterContext from '@/contexts/filterContext';
import {
  getLocalStorageItem,
  LocalStorageKeys,
  setLocalStorageItem,
} from '@/helpers/localStorage';
import {
  JobOrder,
  LabelValueItem,
  LabelValueResult,
  PageResult,
} from '@/models';
import {
  ReadonlyURLSearchParams,
  useRouter,
  useSearchParams,
} from 'next/navigation';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

type CandidateFormOptions = {
  categories: LabelValueResult<number> | null;
  locations: LabelValueResult<string> | null;
  titles: LabelValueResult<string> | null;
};

export default function Jobs() {
  const router = useRouter();
  const searchParams = useSearchParams();

  const [{ categories, locations, titles }, setFilters] =
    useState<CandidateFormOptions>({
      categories: null,
      locations: null,
      titles: null,
    });

  const [filtersSet, setFiltersSet] = useState(false);
  const [jobs, setJobs] = useState<PageResult<JobOrder>>();
  const [page, setPage] = useState(0);
  const [isCacheLoaded, setIsCacheLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isMounted, setIsMounted] = useState(false);
  const [localSearchParams, setLocalSearchParams] =
    useState<ReadonlyURLSearchParams>();

  const [selectedCategories, setSelectedCategories] = useState<
    LabelValueItem<number>[]
  >([]);
  const [selectedIMLC, setSelectedILMC] = useState<LabelValueItem<string>[]>(
    [],
  );
  const [selectedLocations, setSelectedLocations] = useState<
    LabelValueItem<string>[]
  >([]);
  const [selectedTitles, setSelectedTitles] = useState<
    LabelValueItem<string>[]
  >([]);

  const scrollToTop = useCallback(() => {
    const yOffset = -195;
    const element = document.querySelector('main');
    const top = element
      ? element.getBoundingClientRect().top + window.scrollY + yOffset
      : 0;

    window.scrollTo({ top, behavior: 'smooth' });
  }, []);

  const onFilterChange = useCallback(
    <T,>(setSelected: Dispatch<SetStateAction<LabelValueItem<T>[]>>) => {
      return (data: (LabelValueItem<T> | null)[] | null | undefined) => {
        const values = data?.includes(null)
          ? []
          : (data as LabelValueItem<T>[]) || [];
        setSelected(values);
        setPage(0);
        scrollToTop();
      };
    },
    [scrollToTop],
  );

  const onPage = useCallback(
    (nextPage: number) => {
      setPage(currentPage => currentPage + nextPage);
      scrollToTop();
    },
    [scrollToTop],
  );

  const onResetFilter = useCallback(() => {
    setSelectedCategories([]);
    setSelectedILMC([]);
    setSelectedLocations([]);
    setSelectedTitles([]);
    scrollToTop();
  }, [scrollToTop]);

  const getLabelValueItems = useCallback(
    (
      prop: string,
      values: LabelValueResult<string | number> | null,
      params: ReadonlyURLSearchParams | undefined,
    ) => {
      let selected = [] as LabelValueItem<any>[];

      const foundParams = params?.get(prop)?.split(',') || [];

      if (foundParams.length > 0) {
        selected = foundParams.reduce((working, next) => {
          const filter = (values || { data: [] }).data.find(({ value }) => {
            return Number(value)
              ? value === parseInt(next)
              : (value as string).toLowerCase() === next.toLowerCase();
          });
          return filter ? [...working, filter] : working;
        }, [] as LabelValueItem<any>[]);
      }

      return selected;
    },
    [],
  );

  useEffect(() => {
    const getFilters = async () => {
      const requests = [
        fetch('/api/categories'),
        fetch('/api/locations'),
        fetch('/api/titles'),
      ];
      const responses = await Promise.all(requests);
      const values = await Promise.all(
        responses.map(response => response.json()),
      );

      setFilters({
        categories: values[0],
        locations: values[1],
        titles: values[2],
      });

      setFiltersSet(true);
    };

    if (isMounted) {
      getFilters();
    }
  }, [isMounted]);

  useEffect(() => {
    setLocalSearchParams(value => value || searchParams);
    setIsMounted(true);
  }, [searchParams, router]);

  useEffect(() => {
    const abortController = new AbortController();

    const getJobs = async () => {
      let data: PageResult<JobOrder> | null = {
        count: 0,
        data: [],
        start: 0,
        total: 0,
      };

      setIsLoading(true);

      try {
        const baseUrl = `/api/jobs`;
        const start = page * Config.BULLHORN_PAGE_SIZE;
        const queryParams = new URLSearchParams({
          start: `${start}`,
        });

        setLocalStorageItem(
          LocalStorageKeys.selectedCategories,
          selectedCategories,
        );
        setLocalStorageItem(LocalStorageKeys.selectedIMLC, selectedIMLC);
        setLocalStorageItem(
          LocalStorageKeys.selectedLocations,
          selectedLocations,
        );
        setLocalStorageItem(LocalStorageKeys.selectedTitles, selectedTitles);

        selectedCategories.forEach(item =>
          queryParams.append('categoryIds', encodeURIComponent(item.value)),
        );
        selectedIMLC.forEach(item =>
          queryParams.append('imlc', encodeURIComponent(item.value)),
        );
        selectedLocations.forEach(item =>
          queryParams.append('states', encodeURIComponent(item.value)),
        );
        selectedTitles.forEach(item => {
          const titles = item.value?.split(' ').filter(value => !!value) || [];
          titles.forEach(title =>
            queryParams.append('titles', encodeURIComponent(title)),
          );
        });

        const fullUrl = `${baseUrl}?${queryParams}`;
        console.log('Fetching Jobs:', { url: fullUrl }); // Browser log

        const response = await fetch(fullUrl, {
          signal: abortController.signal,
        });

        console.log('Jobs API Response:', { // Browser log
          status: response.status,
          statusText: response.statusText,
          url: response.url,
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error('Error fetching jobs:', { status: response.status, errorText }); // Browser log
          throw new Error(`Fetch failed: ${response.status}`);
        }

        const responseText = await response.text();
        console.log('Raw Jobs Response:', responseText); // Browser log
        data = JSON.parse(responseText) as PageResult<JobOrder>;
        console.log('Parsed Jobs:', { // Browser log
          count: data.count,
          start: data.start,
          total: data.total,
          jobs: data.data?.map(job => ({ id: job.id, title: job.title })),
        });
      } catch (error: any) {
        if (abortController.signal.aborted) {
          data = null;
        } else {
          console.error('Fetch Jobs Error:', error.message); // Browser log
        }
      }

      if (data) {
        setJobs(data);
        setIsLoading(false);
      }
    };

    if (isCacheLoaded) {
      getJobs();
    }

    return () => {
      abortController.abort();
    };
  }, [
    isCacheLoaded,
    page,
    selectedCategories,
    selectedIMLC,
    selectedLocations,
    selectedTitles,
  ]);

  useEffect(() => {
    const checkCacheAndParams = () => {
      const hasSearchParams =
        localSearchParams && Array.from(localSearchParams.keys()).length > 0;

      if (hasSearchParams) {
        setLocalStorageItem(LocalStorageKeys.selectedCategories, []);
        setLocalStorageItem(LocalStorageKeys.selectedIMLC, []);
        setLocalStorageItem(LocalStorageKeys.selectedLocations, []);
        setLocalStorageItem(LocalStorageKeys.selectedTitles, []);
      }

      const categoriesFromParams = getLabelValueItems(
        'categoryIds',
        categories,
        localSearchParams,
      );

      const selectedCategoriesFromState =
        categoriesFromParams.length > 0
          ? categoriesFromParams
          : getLocalStorageItem<LabelValueItem<number>[]>(
              LocalStorageKeys.selectedCategories,
            ) || [];

      setSelectedCategories(selectedCategoriesFromState);

      const selectedIMLCFromState =
        getLocalStorageItem<LabelValueItem<string>[]>(
          LocalStorageKeys.selectedIMLC,
        ) || [];

      setSelectedILMC(selectedIMLCFromState);

      const statesFromParams = getLabelValueItems(
        'states',
        locations,
        localSearchParams,
      );

      const selectedLocationsFromState =
        statesFromParams.length > 0
          ? statesFromParams
          : getLocalStorageItem<LabelValueItem<string>[]>(
              LocalStorageKeys.selectedLocations,
            ) || [];

      setSelectedLocations(selectedLocationsFromState);

      const titlesFromParams = getLabelValueItems(
        'titles',
        titles,
        localSearchParams,
      );
      const selectedTitlesFromState =
        titlesFromParams.length > 0
          ? titlesFromParams
          : getLocalStorageItem<LabelValueItem<string>[]>(
              LocalStorageKeys.selectedTitles,
            ) || [];

      setSelectedTitles(selectedTitlesFromState);

      setIsCacheLoaded(true);
    };

    if (filtersSet) {
      checkCacheAndParams();
    }
  }, [
    filtersSet,
    getLabelValueItems,
    localSearchParams,
    categories,
    locations,
    titles,
  ]);

  return (
    <main>
      <FilterContext.Provider
        value={{
          selectedCategories,
          selectedIMLC,
          selectedLocations,
          selectedTitles,
        }}
      >
        <ScrollToTop />
        <InnerContent>
          <JobContainer
            categories={categories}
            isLoading={isLoading}
            jobs={jobs}
            locations={locations}
            titles={titles}
            onCategoryFilterChange={onFilterChange(setSelectedCategories)}
            onIMLCFilterChange={onFilterChange(setSelectedILMC)}
            onLocationFilterChange={onFilterChange(setSelectedLocations)}
            onPage={onPage}
            onResetFilter={onResetFilter}
            onTitleFilterChange={onFilterChange(setSelectedTitles)}
            scrollToTop={scrollToTop}
          />
        </InnerContent>
      </FilterContext.Provider>
    </main>
  );
}